<template>
  <div>
    <button
      class="btn btn-default btn-sm mt-4 px-5"
      @click="this.$router.go(-1)"
    >
      Back
    </button>
    <div class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0 mb-4">
      <div class="card-body px-lg-5 py-lg-3">
        <div class="row">
          <div class="col-md-12">
            <h2 class>Update Contact Form</h2>
            <hr class="my-0 mb-3" />
          </div>
          <div class="form-group col-md-6">
            <label class="form-control-label">First Name</label>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the name"
              type="text"
              v-model="contactdata.firstName"
            />
            <label
              v-for="error in errors_contact.firstName"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
          <div class="form-group col-md-6">
            <label class="form-control-label">Last Name</label>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the name"
              type="text"
              v-model="contactdata.lastName"
            />
            <label
              v-for="error in errors_contact.lastName"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
          <div class="form-group col-md-6">
            <label class="form-control-label">Email</label>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the email"
              type="text"
              v-model="contactdata.email"
            />
            <label
              v-for="error in errors_contact.email"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
          <div class="form-group col-md-6">
            <label class="form-control-label">Phone</label>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the phone"
              type="text"
              v-model="contactdata.phone"
            />
            <label
              v-for="error in errors_contact.phone"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
          <div class="form-group col-md-6">
            <label class="form-control-label">Gender</label>
            <div class="d-flex">
              <div class="custom-control custom-radio mr-3">
                <input
                  class="custom-control-input"
                  id="gender"
                  name="gender"
                  v-model="contactdata.gender"
                  value="Male"
                  type="radio"
                />
                <label class="custom-control-label" for="gender">Male</label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  id="gender2"
                  name="gender"
                  v-model="contactdata.gender"
                  value="Female"
                  type="radio"
                />
                <label class="custom-control-label" for="gender2">Female</label>
              </div>
            </div>
            <label
              v-for="error in errors_contact.gender"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
          <div class="col-md-6"></div>
          <div class="form-group col-md-6">
            <label class="form-control-label">Title</label>
            <input
              class="form-control form-control-alternative"
              placeholder="Add the title"
              type="text"
              v-model="contactdata.title"
            />
            <label
              v-for="error in errors_contact.title"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
          <div class="col-md-6"></div>
          <div class="form-group col-md-10">
            <label class="form-control-label">Bio</label>
            <rich-text id="bio" @change="handle_bio" :text="bio" />
            <label
              v-for="error in errors_contact.bio"
              :key="error"
              class="form-control-flush text-danger text-sm"
              >{{ error }}</label
            >
          </div>
          <div class="col-md-6">
            <button class="btn btn-secondary px-5" @click="this.$router.go(-1)">
              Cancel
            </button>
            <button class="btn btn-default" @click="update_contact">
              Update Contact
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RichText from "../../components/RichTextEditor.vue";
import axios from "axios";
import { alert } from "../../utils/alertUtils";
export default {
  name: "NewContact",
  components: {
    "rich-text": RichText,
  },
  props: {
    contactUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      entity_id: null,
      contactdata: {},
      bio: "",
      errors_contact: {
        description: [],
      },
    };
  },
  methods: {
    handle_bio: function (bio) {
      this.contactdata.bio = bio;
    },
    get_contact: function () {
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/contacts/${this.contactUuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.contactdata.firstName = res.data.firstName;
          this.contactdata.lastName = res.data.lastName;
          this.contactdata.email = res.data.email;
          this.contactdata.phone = res.data.phone;
          this.contactdata.title = res.data.title;
          this.contactdata.bio = res.data.bio;
          this.bio = res.data.bio;
          this.contactdata.gender = res.data.gender;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    update_contact: function () {
      const url = `${process.env.VUE_APP_BACKEND_URL}/api/contacts/${this.contactUuid}`;
      var data = {
        firstName: this.contactdata.firstName,
        lastName: this.contactdata.lastName,
        email: this.contactdata.email,
        phone: this.contactdata.phone,
        title: this.contactdata.title,
        bio: this.contactdata.bio,
        is_primaryContact: false,
        gender: this.contactdata.gender,
        entity: this.entity_id,
      };
      axios
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res.data);
          alert.success_center("Contact added successfully.");
          this.$router.push({
            name: "EntityDetail",
            params: { uuid: this.$route.params.uuid },
          });
        })
        .catch((err) => {
          alert.error(
            "An error occured in adding contact please try again,if the error persist kindly contact the owner of this site!"
          );
          console.log(err.response);
        });
    },
    get_entity: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.$route.params.uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.entity_id = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.get_entity();
    this.get_contact();
  },
};
</script>